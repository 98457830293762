import React, { useMemo } from 'react';
import './DogAtePopUp.css';
import utilities from '../../services/utilities';

const DogAtePopUp = ({ onCollect, rate, amount }) => {
    const gifs = [
        '1.gif', '2.gif', '3.gif', '4.gif', '5.gif',
        '6.gif', '7.gif', '8.gif', '9.gif', '10.gif'
    ];
    const randomGif = useMemo(() => {
        return gifs[Math.floor(Math.random() * gifs.length)];
    }, []);

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <img 
                    src={`/images/doggo/ate/${randomGif}`} 
                    alt="Cute character with cereal box" 
                    className="popup-image"
                />
                <p className="popup-message">
                    {`We are really sorry, looks like doggo ate your food.
                    Here's an extra ${rate} percent for your troubles..`}
                </p>
                <p className="popup-amount"><img src={`images/coin.png`} alt={'coin'} width={12} height={12} /> +{utilities.formatNumber (amount)}/hr</p>
                <button className="collect-button" onClick={onCollect}>
                    Collect
                </button>
            </div>
        </div>
    );
};

export default DogAtePopUp;