// components/ConfirmationDialog.js
import React from 'react';
import './ConfirmTaskActionDialog.css';
import utilities from '../../services/utilities.js';

const ConfirmTaskActionDialog = ({ title, message, popData, onConfirm, onCancel }) => {
  return (
    <div className="overlay">
      <div className="task-action">
        <div className="close-button" onClick={onCancel}>×</div>
        <h3 style={{fontWeight:'bold', fontSize:'20px',marginBottom: '20px'}}>{popData.name}</h3>
        <div className="card-icon">
          <img  src={`images/tasks/${popData.logo}`} alt={popData?.name} width={100} height={100} />
        </div>
        <p>{popData.description}</p>
        {/* <div className="ratings">
      <img src={`images/coin.png`} alt={popData.name} width={12} height={12} /> 
      <span>+{Math.round( popData.ratePerHour * (popData.rateMultiplier-1) )}/hr</span>
      </div> */}
       <button className="join-button" onClick={onConfirm}>JOIN</button>
        {popData?.levelUpCost ? (
          <div className="price">
            <img src={`images/coin.png`} alt={popData?.name} width={12} height={12} />
            <span> +{popData?.amount.toLocaleString()}</span>
          </div>) : 'Free'
        }
        <button className="check-button" onClick={onConfirm}>CHECK</button>
      </div>

    </div>
  );
};

export default ConfirmTaskActionDialog;
