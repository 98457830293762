import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';

const Game = ({ onBalanceIncrease, onEnergyDecrease, energy, ratePerClick }) => {
  const gameContainerRef = useRef(null);
  const gameInstanceRef = useRef(null);
  const energyRef = useRef(energy);
  const ratePerClickRef = useRef(ratePerClick);
  const energyTextRef = useRef(null);

  useEffect(() => {
    energyRef.current = energy;
    if (energyTextRef.current) {
      energyTextRef.current.setText(`${energy}`);
    }
  }, [energy]);

  useEffect(() => {
    ratePerClickRef.current = ratePerClick;
  }, [ratePerClick]);

  useEffect(() => {
    if (!gameInstanceRef.current) {
      const config = {
        type: Phaser.AUTO,
        transparent: true,
        width: 400,
        height: 400,
        parent: gameContainerRef.current,
        pixelArt: false,
        scene: {
          preload,
          create,
          update,
        },
        input: {
          activePointers: 5 // Enable multi-touch (up to 3 touches)
        }
      };
      gameInstanceRef.current = new Phaser.Game(config);
    }

    function preload() {
      this.load.image('circle', 'images/main_screen.png');
    }

    function create() {
      const circle = this.add.image(200, 190, 'circle').setInteractive();
      circle.setScale(0.75);

      // Add energy counter text
      energyTextRef.current = this.add.text(256, 205, `${energyRef.current}`, {
        fontSize: '14px',
        fontStyle: 'bold',
        fontFamily: 'curior',
        fill: '#ffffff',
        align: 'center'
      });
      energyTextRef.current.setOrigin(0.5, 0.5);

      // Use onInputDown instead of 'pointerdown' event
      this.input.on('pointerdown', (pointer) => {
        if (energyRef.current >= ratePerClickRef.current) {
          onBalanceIncrease();
          onEnergyDecrease();
          showPlusOneAnimation(this, pointer.x, pointer.y);
          energyTextRef.current.setText(`${energyRef.current - 1}`);
        }
      });
    }

    function update() {}

    function showPlusOneAnimation(scene, x, y) {
      const plusOne = scene.add.text(x, y, `+${ratePerClickRef.current}`, {
        fontSize: '44px',
        fontStyle: 'bold',
        fontFamily: 'Courier',
        fill: '#ffffff',
      });
      plusOne.setOrigin(0.5, 0.5);
      scene.tweens.add({
        targets: plusOne,
        y: y - 50,
        alpha: 0,
        duration: 1000,
        ease: 'Power2',
        onComplete: () => {
          plusOne.destroy();
        },
      });
    }

    return () => {
      if (gameInstanceRef.current) {
        // Uncomment these lines if you want to destroy the game instance on component unmount
        // gameInstanceRef.current.destroy(true);
        // gameInstanceRef.current = null;
      }
    };
  }, [onBalanceIncrease, onEnergyDecrease]);

  return <div ref={gameContainerRef} id="phaser-game-container"></div>;
};

export default Game;