import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import utilities from "../../services/utilities";
import Coins from "../bakery/Coins";
import "./UserFriend.css";
import InviteLevelConfirmationDialog from "./InviteLevelConfirmationDialog";
import EarnMoreRewards from "./EarnMoreRewards";
import toast, { Toaster } from "react-hot-toast";
import Confetti from "react-confetti";
import ProfileName from "../common/ProfileName";
import UserFriendStats from "./UserFriendStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const friendList = [
  { first_name: "Akash", balance: 200, level: "Lvl 1", friends: 25 },
  { first_name: "Vishal", balance: 20, level: "Lvl 2", friends: 12 },
  { first_name: "Arjun", balance: 30, level: "Lvl 3", friends: 2 },
  { first_name: "Prakash", balance: 33, level: "Lvl 4", friends: 75 },
];

const UserFriend = ({ balance, IsDisplayWelcome }) => {
  const [friends, setFriends] = useState([]);
  const [inviteLevelCounts, setInviteLevelCounts] = useState([]);
  const [UrlLink, setUrlLink] = useState("");
  const [curLevelPoint, setCurLevelPoint] = useState(500000);
  const [showLevelConfirmation, setshowLevelConfirmation] = useState(false);
  const [showLevelDetails, setShowLevelDetails] = useState(false);
  const [curInviteLevel, setCurInviteLevel] = useState(0);
  const [showFireWorks, setshowFireWorks] = useState(false);
  const [showUserStatus, setshowUserStatus] = useState(false);
  const { width, height } = window;
  const [isBuddiesView, setIsBuddiesView] = useState(true);

  const notifyToast = (type, msg) => {
    if (type == "success") {
      toast.success(msg == null ? "Updated Successfully..." : msg);
    } else if (type == "error") {
      toast.error(msg == null ? "Action Failed..." : msg);
    } else if (type == "wait") {
      toast(msg == null ? "Processing data..." : msg);
    }
  };

  const updateInviteLevel = async () => {
    const response = await apiService.post("/updateInviteLevel", {
      point: parseInt(curLevelPoint),
      level: curInviteLevel,
    });
    console.log("updateInviteLevel", response);
  };

  useEffect(() => {
    if (
      window.Telegram &&
      window.Telegram.WebApp.initDataUnsafe &&
      window.Telegram.WebApp.initDataUnsafe.user
    ) {
      let telegrmaID = window.Telegram.WebApp.initDataUnsafe.user.id;
      setUrlLink(`https://t.me/atdemoapp_bot/taptowin?startapp=refid`);
      if (telegrmaID) {
        setUrlLink(
          `https://t.me/atdemoapp_bot/taptowin?startapp=refid${telegrmaID}`
        );
      }
    }
  }, []);

  useEffect(() => {
    if (IsDisplayWelcome == true) {
      setshowFireWorks(true);
      setTimeout(() => {
        setshowFireWorks(false);
      }, 4000);
    }
    fetchFriends();
  }, []);

  const fetchFriends = async () => {
    try {
      const response = await apiService.get("/friend");
      // response.data.data = friendList;
      const friendData = response.data.data || [];
      setFriends(Array.isArray(friendData) ? friendData : []);
    } catch (error) {
      console.error("Error fetching mining cards:", error);
    }
  };

  useEffect(() => {
    const checkInvites = async () => {
      try {
        const response = await apiService.get("/check-invites");
        const inviteData = response.data.data || [];

        if (inviteData.curInviteLevel == 0 && inviteData.inviteCount >= 5) {
          setCurLevelPoint(250000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        } else if (
          inviteData.curInviteLevel == 1 &&
          inviteData.inviteCount >= 10
        ) {
          setCurLevelPoint(500000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        } else if (
          inviteData.curInviteLevel == 2 &&
          inviteData.inviteCount >= 25
        ) {
          setCurLevelPoint(2500000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        } else if (
          inviteData.curInviteLevel == 3 &&
          inviteData.inviteCount >= 50
        ) {
          setCurLevelPoint(5000000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        } else if (
          inviteData.curInviteLevel == 4 &&
          inviteData.inviteCount >= 100
        ) {
          setCurLevelPoint(10000000);
          setCurInviteLevel(inviteData.curInviteLevel + 1);
          setshowLevelConfirmation(true);
        }

        if (inviteData.inviteCount != friends.length) {
          fetchFriends();
        }
        // setFriends(Array.isArray(inviteData) ? friendData : []);
      } catch (error) {
        console.error("Error fetchInvites:", error);
      }
    };
    console.log("checkInvites is called");
    checkInvites();
    const intervalId = setInterval(checkInvites, 10000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      if (showLevelDetails) {
        handleClose();
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showLevelDetails]);

  const handleClaim = async () => {
    try {
      notifyToast("success", "Claim Successfull...");
      updateInviteLevel();
    } catch (error) {
      console.error("Error fetching mining cards:", error);
    }

    setshowLevelConfirmation(false);
    setshowFireWorks(true);
    setTimeout(() => {
      setshowFireWorks(false);
    }, 4000);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(UrlLink)
      .then(() => {
        console.log("url" + UrlLink);
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const handleLevelDetailsClick = async () => {
    setShowLevelDetails(true);
    window.history.pushState(null, null);
  };

  const handleClose = () => {
    setshowLevelConfirmation(false);
    setShowLevelDetails(false);
    window.history.back();
  };

  const handleInviteClick = async () => {
    const url = `https://t.me/share/url?url=` + encodeURIComponent(UrlLink);
    window.location.href = url;
  };

  const handleOnProfileClick = () => {
    setshowUserStatus(true);
    // onProfileClick();
  };
  const handleOnStatusExit = () => {
    setshowUserStatus(false);
    // onProfileClick();
  };
  const handleToggleView = () => {
    setIsBuddiesView(!isBuddiesView);

    if (!isBuddiesView) {
      fetchInviteLevelCounts();
    }
  };

  useEffect(() => {
    fetchInviteLevelCounts();
  }, []);

  // const fetchInviteLevelCounts = async () => {
  //   try {
  //     const response = await apiService.get("/getInviteLevelCounts");
  //     console.log("fetchInviteLevelCounts", response.data);
  //     const lst = response.data.data || [];

  //     setInviteLevelCounts(Array.isArray(lst) ? lst : []);
  //   } catch (error) {
  //     console.error("Error fetching invite level counts:", error);
  //   }
  // };

  const fetchInviteLevelCounts = async () => {
    try {
      const response = await apiService.get("/users");
      const userResponse = await apiService.get("/current-user");
      const lst = response.data.data || [];

      const user = userResponse.data.data;
      console.log("LST",lst, userResponse);
      let filteredList = lst.filter((item) => item._id != user._id);

      setInviteLevelCounts(Array.isArray(filteredList) ? filteredList : []);
    } catch (error) {
      console.error("Error fetching invite level counts:", error);
    }
  };

  return (
    <>
      {showUserStatus && <UserFriendStats OnStatusExit={handleOnStatusExit} />}
      {!showUserStatus && (
        <div className="invite-friend-container">
          {showFireWorks && (
            <Confetti
              width={width}
              height={height}
              gravity={0.15}
              numberOfPieces={600}
              recycle={false}
            />
          )}

          <Toaster />
          <div className="invite-friend-content">
            <h2 className="invite-heading">Invite Friends!</h2>
            <div className="buddied-image-container">
              <img
                src="images/Ued-3.png"
                alt="buddy"
                className="friend-cute-character"
              />
            </div>

            <h2 onClick={handleLevelDetailsClick} className="earn-more-text">
              Earn More!!
            </h2>
            <p className="friends-reward-text">
              You and your buddies will get +50 tokens.
            </p>
            <div className="friends-tab">
              <div className="toggle-container">
                <span
                  className={`toggle-item ${isBuddiesView ? "active" : ""}`}
                  onClick={handleToggleView}
                >
                  {friends.length} {friends.length === 1 ? "Buddy" : "Buddies"}
                </span>
                <span
                  className={`toggle-item ${!isBuddiesView ? "active" : ""}`}
                  onClick={handleToggleView}
                >
                  List
                </span>
              </div>

              <ul className="invite-friend-list">
                {isBuddiesView &&
                  friends.map((friend, index) => (
                    <li key={index} className="invite-friend-item">
                      <div className="friend-avatar">
                        {friend.first_name[0].toUpperCase()}
                      </div>
                      <span className="friend-name">{friend.first_name}</span>
                      <span className="invite-friend-tokens">
                        <img src="images/coin.png" height={15} width={15} />
                        {utilities.formatNumber(friend.balance || 0)}{" "}
                        <div className="divider" /> 25{" "}
                        <img
                          src="images/user-filled.png"
                          height={15}
                          width={15}
                        />
                      </span>
                    </li>
                  ))}
                {!isBuddiesView &&
                  inviteLevelCounts.map((levelData, index) => (
                    <li key={index} className="friend-item">
                      <div className="friend-avatar">
                        {levelData.curInviteLevel}
                      </div>
                      <div className="friend-list-name">
                        <span>{levelData.first_name}</span>
                        Level {levelData.curInviteLevel}
                      </div>
                      <span
                        className="friend-tokens"
                        style={{
                          fontSize: "larger",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src="images/coin.png" height={15} width={15} />
                        <span
                          style={{
                            margin: "0px 4px",
                          }}
                        >
                          {utilities.formatNumber(levelData.balance || 0)}
                        </span>
                        |
                        <span
                          className="friend-name"
                          style={{
                            marginRight: "4px",
                            marginLeft: "4px",
                          }}
                        >
                          {levelData.friends?.length}
                        </span>
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{
                            fontSize: "large",
                            color: "black",
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="invite-btn-container">
              <button className="inviteBtn" onClick={handleInviteClick}>
                Invite a Buddy
              </button>
              <button className="copyBtn" onClick={copyToClipboard}>
                <svg
                  width="26"
                  height="28"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ transform: "scaleX(-1)" }}
                >
                  <path
                    d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>

            {showLevelConfirmation && (
              <InviteLevelConfirmationDialog
                title={`LEVEL ${curInviteLevel}`}
                imageName={`Level_${curInviteLevel}`}
                description={`Collect your rewards`}
                points={curLevelPoint}
                onConfirm={handleClaim}
                onCancel={handleClose}
              />
            )}
            {showLevelDetails && (
              <EarnMoreRewards onConfirm={handleClose} onCancel={handleClose} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserFriend;
