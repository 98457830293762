import React, { useState, useEffect } from "react";
import DailyReward from "../gifts/DailyReward";
import ConfirmTaskActionDialog from "../common/ConfirmTaskActionDialog";
import apiService from "../../services/apiService";
import toast, { Toaster } from "react-hot-toast";
import "rsuite/dist/rsuite.min.css";
import { IconButton } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import CountdownButton from "./timerButton";

import "./Tasks.css";

const Tasks = () => {
  const [dailyReward, setDailyReward] = useState(false);
  const [selectedTask, setSelectedTask] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    name: "Join Our Telegram",
    amount: 10000,
    description: "",
    levelUpCost: 300,
  });
  const [tasks, setTasks] = useState([]);
  const [friendCount, setFriendCount] = useState(0);
  const [IsLoading, setIsLoading] = useState(0);
  const [loadingId, setloadingId] = useState("");
  const [delayInClaim, setDelayInClaim] = useState(0); // Timer duration
  const [showWaiting, setShowWaiting] = useState(false); // Controls timer visibility

  const [taskTimers, setTaskTimers] = useState({});

  //   useEffect(() => {
  //     userTasks();

  //     // Check for any active timers on component mount
  //     const timerStart = localStorage.getItem("taskTimerStart");
  //     const delayInMs = localStorage.getItem("taskTimerDelay");

  //     if (timerStart && delayInMs) {
  //       const elapsedTime = Date.now() - parseInt(timerStart, 10);
  //       const remainingTime = Math.max(
  //         Math.ceil((delayInMs - elapsedTime) / 1000),
  //         0
  //       );

  //       if (remainingTime > 0) {
  //         setDelayInClaim(remainingTime);
  //         setShowWaiting(true);

  //         // Start interval to update the timer in real-time
  //         const interval = setInterval(() => {
  //           setDelayInClaim((prev) => {
  //             if (prev <= 1) {
  //               clearInterval(interval);
  //               setShowWaiting(false);
  //               localStorage.removeItem("taskTimerStart");
  //               localStorage.removeItem("taskTimerDelay");
  //               return 0;
  //             }
  //             return prev - 1;
  //           });
  //         }, 1000);

  //         return () => clearInterval(interval); // Clean up interval on unmount
  //       } else {
  //         // Timer expired, reset the state
  //         setDelayInClaim(0);
  //         setShowWaiting(false);
  //         localStorage.removeItem("taskTimerStart");
  //         localStorage.removeItem("taskTimerDelay");
  //       }
  //     }
  //   }, []);

  useEffect(() => {
    userTasks();
  }, []);

  useEffect(() => {
    tasks.forEach((task) => {
      const timerStart = localStorage.getItem(`task_${task._id}_start`);
      const delayInMs = localStorage.getItem(`task_${task._id}_delay`);

      if (timerStart && delayInMs) {
        const elapsedTime = Date.now() - parseInt(timerStart, 10);
        const remainingTime = Math.max(
          Math.ceil((delayInMs - elapsedTime) / 1000),
          0
        );

        if (remainingTime > 0) {
          setTaskTimers((prevTimers) => ({
            ...prevTimers,
            [task._id]: {
              delayInClaim: remainingTime,
              showWaiting: true,
            },
          }));

          const interval = setInterval(() => {
            setTaskTimers((prevTimers) => {
              const currentTaskTimer = prevTimers[task._id] || {};

              if (currentTaskTimer.delayInClaim <= 1) {
                clearInterval(interval);
                localStorage.removeItem(`task_${task._id}_start`);
                localStorage.removeItem(`task_${task._id}_delay`);

                return {
                  ...prevTimers,
                  [task._id]: { delayInClaim: 0, showWaiting: false },
                };
              }

              return {
                ...prevTimers,
                [task._id]: {
                  delayInClaim: currentTaskTimer.delayInClaim - 1,
                  showWaiting: currentTaskTimer.delayInClaim > 1,
                },
              };
            });
          }, 1000);
        }
      }
    });
  }, [tasks]);

  const userTasks = async () => {
    try {
      const response = await apiService.post("/user-tasks");
      console.log("user-tasks response ", response.data);
      const tasks = response.data.data.tasks || [];
      const count = response.data.data.friendCount || 0;
      setTasks(Array.isArray(tasks) ? tasks : []);
      setFriendCount(count);
      console.log("userTasks ", tasks);
    } catch (error) {
      console.error("Error fetching tasks :", error);
    }
  };

  const handleTaskConfirm = async () => {
    try {
      console.log("handleTaskConfirm");
      setSelectedTask(null);
    } catch (error) {
      console.log("Error upgrading the card. Please try again later.");
    }
  };

  const handleTaskCancel = () => {
    console.log("handleTaskConfirm");
    setSelectedTask(null);
  };

  const taskOnClick = (task) => {
    setSelectedValue(task);
    if (task.name === "Daily Rewards" && task.status === "Start") {
      toggleDailyReward();
    } else {
      setIsLoading(true);
      setloadingId(task._id);
      updateTasks(task);
    }
  };

  const handleOnComplete = (taskId) => {
    setShowWaiting(false);
    setDelayInClaim(0);
    setTaskTimers((prevTimers) => ({
      ...prevTimers,
      [taskId]: {
        delayInClaim: 0,
        showWaiting: false,
      },
    }));
    localStorage.removeItem(`task_${taskId}_start`);
    localStorage.removeItem(`task_${taskId}_delay`);
  };

  //   const updateTasks = async (task) => {
  //     try {
  //       const oldStatus = task.status;
  //       if (task.status === "Start") {
  //         task.status = "Claim";
  //       } else if (task.status === "Claim") {
  //         task.status = "Done";
  //       }

  //       const response = await apiService.post("/update-task", task);
  //       if (task.delayOnStatusUpdate > 0) {
  //         const timerStart = Date.now();
  //         const delayInMs = task.delayOnStatusUpdate * 1000;
  //         localStorage.setItem("taskTimerStart", timerStart); // Save start time
  //         localStorage.setItem("taskTimerDelay", delayInMs); // Save delay duration
  //         setDelayInClaim(task.delayOnStatusUpdate);
  //         setShowWaiting(true);
  //       } else {
  //         setShowWaiting(false);
  //       }
  //       if (
  //         task.status !== "Done" &&
  //         (task.url !== undefined || task.url !== null)
  //       ) {
  //         if (task.url && task.url.startsWith("https://www.youtube.com/watch")) {
  //           // Open the YouTube link
  //           window.open(task.url, "_blank");
  //         }
  //       }

  //       // Claim
  //       if (task.status === "Done") {
  //         toast.success(`Congrats! ${task.coins} added to the balance.`);
  //         setTasks(tasks);
  //         setIsLoading(false);
  //         setloadingId("");
  //       } else {
  //         setTimeout(() => {
  //           setTasks(tasks);
  //           setIsLoading(false);
  //           setloadingId("");
  //         }, 2000);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching tasks :", error);
  //     }
  //   };

  const updateTasks = async (task) => {
    try {
      const oldStatus = task.status;
      if (task.status === "Start") {
        task.status = "Claim";
      } else if (task.status === "Claim") {
        task.status = "Done";
      }

      const response = await apiService.post("/update-task", task);

      if (task.delayOnStatusUpdate > 0 && task.status === "Claim") {
        const timerStart = Date.now();
        const delayInMs = task.delayOnStatusUpdate * 1000;
        localStorage.setItem(`task_${task._id}_start`, timerStart); // Save start time
        localStorage.setItem(`task_${task._id}_delay`, delayInMs); // Save delay duration

        setTaskTimers((prevTimers) => ({
          ...prevTimers,
          [task._id]: {
            delayInClaim: task.delayOnStatusUpdate,
            showWaiting: true,
          },
        }));
      } else {
        setTaskTimers((prevTimers) => ({
          ...prevTimers,
          [task._id]: {
            delayInClaim: 0,
            showWaiting: false,
          },
        }));
      }

      // Open YouTube or external link
      if (
        task.status !== "Done" &&
        (task.url !== undefined || task.url !== null)
      ) {
        if (task.url && task.url.startsWith("https://www.youtube.com/watch")) {
          // Check if window.Telegram is available
          if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(task.url);
          } else {
            console.warn(
              "Telegram object is not available, falling back to window.open"
            );
            window.open(task.url, "_blank");
          }
        }
      }

      // Claim
      if (task.status === "Done") {
        toast.success(`Congrats! ${task.coins} added to the balance.`);
        setTasks(tasks);
        setIsLoading(false);
        setloadingId("");
      } else {
        setTimeout(() => {
          setTasks(tasks);
          setIsLoading(false);
          setloadingId("");
        }, 2000);
      }
    } catch (error) {
      console.error("Error fetching tasks :", error);
    }
  };

  const toggleDailyReward = () => setDailyReward((prev) => !prev);

  function formatNumberWithCommas(number) {
    return new Intl.NumberFormat("en-US").format(number);
  }

  return (
    <div className="tasks-container">
      <div className="tasks-content">
        <div className="task-list-title">Quest</div>
        <Toaster />
        <div className="task-image-container">
          <img
            src="images/tasks/tasks-256.png"
            alt="buddy"
            className="task-cute-character"
          />
        </div>
        <p className="task-instruction-text">
          Complete the following tasks and grab more coins
        </p>
        <div
          className="tasks-list"
          style={{ paddingTop: "0px", marginTop: "0px" }}
        >
          <h3
            className="task-list-heading"
            style={{ color: "#CDEB45", textAlign: "start", fontsize: "20px" }}
          >
            Tasks List
          </h3>

          {/* {tasks.map((task, index) => (
            <div key={index} className="task-item">
              <div className="task-info">
                <span className="task-icon">
                  <img
                    src={`images/tasks/${task.taskIcon}.png`}
                    height={50}
                    width={50}
                    alt="Quest"
                  />
                </span>
              </div>
              <div className="task-content">
                <span className="task-title">{task.name}</span>
                <span className="task-coins">
                  <img src="images/coin.png" height={12} width={12} />{" "}
                  {task.coins}
                </span>
              </div>
              <div className="task-reward">
                {task.status === "Start" && (
                  <button
                    className="start-button"
                    disabled={IsLoading}
                    onClick={() => taskOnClick(task)}
                  >
                    {loadingId === task._id ? "Working..." : "Start"}
                  </button>
                )}

                {task.status === "Claim" &&
                  (loadingId === task._id ? (
                    <IconButton
                      style={{
                        backgroundColor: "#CDEB45",
                        borderRadius: "15px",
                        width: "70px",
                        padding: "7px",
                      }}
                      appearance="default"
                      loading
                      icon={<SearchIcon />}
                    />
                  ) : showWaiting ? (
                    <CountdownButton
                      timerCount={delayInClaim}
                      onComplete={handleOnComplete}
                    />
                  ) : (
                    <span
                      className="claim-button"
                      appearance="default"
                      onClick={() => taskOnClick(task)}
                    >
                      Claim
                    </span>
                  ))}

                {task.status === "Done" && (
                  <>
                    <div style={{ width: "70px" }}>
                      <img
                        style={{ marginLeft: "20px" }}
                        height={24}
                        width={24}
                        src="images/tasks/check_mark_100.png"
                        alt="icon"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ))} */}

          {tasks.map((task, index) => (
            <div key={index} className="task-item">
              <div className="task-info">
                <span className="task-icon">
                  <img
                    src={`images/tasks/${task.taskIcon}.svg`}
                    height={50}
                    width={50}
                    style={{ marginRight: "12px" }}
                    alt="Quest"
                  />
                </span>
              </div>
              <div className="task-content">
                <span className="task-title">{task.name}</span>
                <span className="task-coins">
                  <img src="images/coin.png" height={14} width={14} style={{ marginRight: "4px" }} /> +
                  {formatNumberWithCommas(task.coins)}
                </span>
              </div>
              <div className="task-reward">
                {task.status === "Start" && (
                  <button
                    className="start-button"
                    disabled={IsLoading}
                    onClick={() => taskOnClick(task)}
                  >
                    {loadingId === task._id ? "Working..." : "Start"}
                  </button>
                )}

                {task.status === "Claim" &&
                  (loadingId === task._id ? (
                    <IconButton
                      style={{
                        backgroundColor: "#CDEB45",
                        borderRadius: "15px",
                        width: "70px",
                        padding: "7px",
                      }}
                      appearance="default"
                      loading
                      icon={<SearchIcon />}
                    />
                  ) : taskTimers[task._id]?.showWaiting ? (
                    <CountdownButton
                      timerCount={taskTimers[task._id]?.delayInClaim}
                      onComplete={() => handleOnComplete(task._id)}
                    />
                  ) : (
                    <span
                      className="start-button claim"
                      appearance="default"
                      onClick={() => taskOnClick(task)}
                    >
                      Claim
                    </span>
                  ))}

                {task.status === "Done" && (
                  <>
                    <div style={{ width: "70px" }}>
                      <img
                        style={{ marginLeft: "20px" }}
                        height={24}
                        width={24}
                        src="images/tasks/check_mark_100.png"
                        alt="icon"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {dailyReward && <DailyReward onCancel={toggleDailyReward} />}

      {selectedTask && (
        <ConfirmTaskActionDialog
          title="Confirm Selection"
          message={`Are you sure you want to upgrade the card?`}
          onConfirm={handleTaskConfirm}
          onCancel={handleTaskCancel}
          popData={selectedValue}
        />
      )}
    </div>
  );
};

export default Tasks;
