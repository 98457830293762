import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import utilities from "../../services/utilities";
import "./UserScoreAndRank.css";
import Coins from "../bakery/Coins";

const UserScoreAndRank = ({ balance, OnStatusExit }) => {
  const [friends, setFriends] = useState({});
  const [top10UsersByFriends, setUsersByFriends] = useState([]);
  const [top10UsersByBalance, setUsersByBalance] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUserStats = async () => {
    try {
      const response = await apiService.get("/user-stats");
      const data = response.data.data || {};
      setFriends(data);
      console.log("data:", data);
      setUsersByBalance(
        Array.isArray(data.top10UsersByBalance) ? data.top10UsersByBalance : []
      );
      setUsersByFriends(
        Array.isArray(data.top10UsersByFriends) ? data.top10UsersByFriends : []
      );
    } catch (error) {
      console.error("Error fetching user stats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("fetching user stats");
    fetchUserStats();
  }, []);

  return (
    <div style={{ width: "98%" }} className="user-friend-container">
      {loading ? (
        <h2 style={{ color: "white", textAlign: "center" }}>Loading...</h2>
      ) : (
        <div className="user-friend-content">
          <span style={{ color: "white" }} onClick={OnStatusExit}>
            Go Back
          </span>
          <h3 style={{ color: "white" }}>Leader Board</h3>
          <Coins balance={balance} />
          <div className="friends-container">
            <h2
              style={{ color: "#CDEB45", textAlign: "left", marginTop: "20px" }}
            >
              Top 10 Users By Score
            </h2>
            <ul className="friend-list" style={{ minHeight: "400px" }}>
              {top10UsersByBalance.length > 0 &&
                top10UsersByBalance.map((friend, index) => (
                  <li key={index} className="friend-item">
                    <div className="friend-avatar">
                      {friend.first_name
                        ? friend.first_name.charAt(0).toUpperCase()
                        : ""}
                    </div>
                    <span className="friend-name">{friend.first_name}</span>
                    <span className="friend-tokens">
                      <img
                        src="images/coin.png"
                        height={15}
                        width={15}
                        style={{
                          verticalAlign: "middle",
                          marginRight: "-2px",
                          marginTop: "10px",
                        }}
                      />{" "}
                      <span style={{ verticalAlign: "middle" }}>
                        {" "}
                        {utilities.formatNumber(friend.balance || 0)}
                      </span>{" "}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserScoreAndRank;
