
// components/TileButton.js
import React from 'react';
import Coins from './Coins';

import './BoosterItem.css';
import utilities from '../../services/utilities';

const BoosterItem = ({index, booster ,fullTankTimeLeft , formatTime, handleCardClick }) => {
  return (
    <div key={index} className="booster-item" onClick={() => handleCardClick(booster)}>
        <div className="booster-info">
        <span className="booster-icon"><img src={`images/booster/${booster.image}.png`} alt="Booster" height={40} width={40}/></span>
        
        </div>
        <div className='booster-content'>
        <span className="task-title">{booster.name}</span>
        {
           (booster.key === 'fulltank') && (
            <>
                {fullTankTimeLeft ? 
                <span className="time-indicator">{formatTime(fullTankTimeLeft)}</span> : ''}
                <div>{booster.currentFullTankCount}/{booster.maxFullTankCount}</div>
            </>)
        }
        <div className='booster-data'>
        {
            booster.isUpgradable && ( 
            <span className="booster-coins">
                <img src='images/coin.png' height={12} width={12} /> {utilities.formatNumber (booster.levelUpCost)}
            </span>)
        }
        {
            (booster.isUpgradable) && ( 
            <span className="booster-level">
                <img src='images/star.png' height={12} width={12} />lvl {booster.level}
            </span>)
        }
        </div>
        </div>
        <div className="booster-reward">
            <button className="claim-button"> {'>'} </button>
        </div>
    </div>
  );
};

export default BoosterItem;





