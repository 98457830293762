
// components/ConfirmationDialog.js
import React from 'react';
import './Card.css';
import utilities from '../../services/utilities.js';

const Card = ({ index, card, handleCardClick }) => {
  return (
    <div
    key={index}
    className={`card ${card.isLocked ? 'locked' : ''}`}
    onClick={() => handleCardClick(card)}
    >
    <img className='icon-image' src={`images/cards/${card.image}.png`} alt={card.name} />
    <div className="level">lvl {card.level}</div>
    <h3>{card.name}</h3>
    <div className="profit">
    <img src={`images/coin.png`} alt={card.name} width={12} height={12} />
    {utilities.formatNumber (card.ratePerHour)}/hr</div>
    {!card.isLocked && <div className="pay">Pay {utilities.formatNumber (card.levelUpCost)} </div>}
    {card.isLocked && (
      <>
        <div className="lock-icon">🔒</div>
        <div className="pay"> {card.cardText}</div>
      </>
    )}
    </div>
  );
};

export default Card;



