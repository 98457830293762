import React, { useState, useEffect } from "react";
import { tabIcons } from "../../assets/tabs/tabIcon.js";
import "./Tabs.css";

let xPos = {
  0: -3,
  1: 55,
  2: 114,
  3: 172,
  4: 230,
  5: 289,
};

const Tabs = ({ activeTab, setActiveTab, setBakeryPage, bakeryPage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [menuItemWidth, setMenuItemWidth] = useState(0);

  const tabsData = [
    { name: "Farming", icon: "bakery" },
    { name: "Leader", icon: "leader" },
    { name: "Friends", icon: "friends" },
    { name: "Gifts", icon: "gifts" },
    { name: "Shop", icon: "shop" },
    { name: "Casino", icon: "casino" },
  ];

  useEffect(() => {
    const activeTabIndex = tabsData.findIndex(
      (item) => item.name.toLowerCase() === activeTab
    );
    if (activeTabIndex !== -1) {
      setActiveIndex(activeTabIndex);
    }
  }, [activeTab, tabsData]);

  useEffect(() => {
    const updateIndicator = () => {
      const listItem = document.querySelector(".list");
      if (listItem) {
        setMenuItemWidth(listItem.offsetWidth);
        // setIndicatorPosition(activeIndex * listItem.offsetWidth - 3);
        setIndicatorPosition(xPos[activeIndex]);
      }
    };

    updateIndicator();
    window.addEventListener("resize", updateIndicator);

    return () => window.removeEventListener("resize", updateIndicator);
  }, [activeIndex, bakeryPage]);

  if (bakeryPage == "landing" || bakeryPage == "onboarding") {
    return null;
  }

  return (
    <div className="navigation">
      <ul>
        {tabsData.map((item, index) => {
          const Icon = tabIcons[item.icon];
          return (
            <li
              key={index}
              className={`list ${activeIndex === index ? "active" : ""}`}
              onClick={() => {
                setActiveIndex(index);
                setActiveTab(item.name.toLowerCase());
              }}
            >
              <a href="#!" onClick={(e) => e.preventDefault()}>
                <span className="icon">
                  <Icon
                    style={{ fill: activeIndex === index ? "blue" : "gray" }}
                  />
                </span>
                <span className="text">{item.name}</span>
              </a>
            </li>
          );
        })}
        <div
          className="indicator"
          style={{
            width: `${menuItemWidth + 6}px`,
            height: `${menuItemWidth + 6}px`,
            transform: `translateX(${indicatorPosition}px)`,
          }}
        ></div>
      </ul>
    </div>
  );
};

export default Tabs;
