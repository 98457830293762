import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import "./farming.css";
import UserScoreAndRank from "./UserScoreAndRank";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faChevronCircleRight,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import Button3D from "../Button3d";

const Farming = ({ balance, OnTaskClick, OnRankClick }) => {
  const [displayBalance, setdisplayBalance] = useState(balance);
  const [userName, setUserName] = useState("Guest");
  const [timeLeft, setTimeLeft] = useState(0); // Time left in seconds
  const [buttonCaption, setButtonCaption] = useState("Start");
  const [timerStatus, setTimerStatus] = useState("Waiting");
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const imageUrl = '"images/avator-image-256.png"';

  //const FIVE_HOURS_IN_SECONDS = 5 * 60 * 60;
  const FIVE_HOURS_IN_SECONDS = 30;
  useEffect(() => {
    setdisplayBalance(balance);
    console.log("Farming component mounted balance", balance);
    console.log("displayBalance", displayBalance);
  }, [balance]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };

  useEffect(() => {
    if (
      window.Telegram &&
      window.Telegram.WebApp.initDataUnsafe &&
      window.Telegram.WebApp.initDataUnsafe.user
    ) {
      setUserName(window.Telegram.WebApp.initDataUnsafe.user.first_name);
    }

    getMiningStattus();
  }, []);

  const handleCheckScoreClick = async () => {
    setShowLeaderBoard(true);
    window.history.pushState(null, null);
  };

  const handleOnStatusExit = () => {
    setShowLeaderBoard(false);
  };

  const getMiningStattus = async () => {
    try {
      var res = await apiService.get("/mining-status");
      console.log("getMiningStattus ", res.data);
      if (res.data.data) {
        var miningData = res.data.data;
        if (miningData.miningStatus == "Started") {
          var miningEndTime = new Date(miningData.miningEndTime);
          var timeleftinSeconds = Math.floor(
            (miningEndTime.getTime() - new Date().getTime()) / 1000
          );
          //var timeleftinSeconds = Math.floor((startedOn.getTime() + 5 * 60 * 60 * 1000 - new Date().getTime()) / 1000);
          setTimeLeft(timeleftinSeconds);
          if (timeleftinSeconds < 1 && miningData.miningStatus == "Started") {
            setButtonCaption("Claim 50");
          } else {
            setTimerStatus("Started");
          }
          // handleStartClaimClick();
        }
      }
    } catch (error) {
      console.error("Error inviteUser : ", error);
    }
  };

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
        setButtonCaption(formatTime(timeLeft));
      }, 1000);
    } else if (timeLeft === 0 && timerStatus == "Started") {
      setButtonCaption("Claim 50");
    }

    return () => clearInterval(timer);
  }, [timeLeft, buttonCaption]);

  const handleStartClaimClick = () => {
    if (buttonCaption === "Start") {
      setTimeLeft(FIVE_HOURS_IN_SECONDS);
      setButtonCaption("00:00:00");
      setTimerStatus("Started");
      setMining("Started");
    } else if (buttonCaption === "Claim 50") {
      setButtonCaption("Start");
      setTimerStatus("Waiting");
      setTimeLeft(0);
      setMining("Completed");
      claimRewards();
    }
  };

  const setMining = async (status) => {
    try {
      console.log("setMining ", status);

      var res = await apiService.post("/mining-status", {
        status: status,
        seconds: FIVE_HOURS_IN_SECONDS,
      });
      console.log("setMining ", res);
    } catch (error) {
      console.error("Error inviteUser : ", error);
    }
  };

  const onClickJoin = async () => {
    window.location.href = "https://t.me/crimsonwheel";
  };

  const claimRewards = async () => {
    try {
      const response = await apiService.post("/balance", {
        amount: 50,
        reason: "farming",
        description: "Claimed 50 coins",
      });
      if (response.data.data) {
        console.log("Claimed 50 coins", response.data.data);
        toast.success("Score updated with 50 coins.");
        setdisplayBalance((prevBalance) => prevBalance + 50);
      }
    } catch (error) {
      console.error("Error fetching mining cards:", error);
    }
  };

  return (
    <>
      {showLeaderBoard && (
        <UserScoreAndRank balance={balance} OnStatusExit={handleOnStatusExit} />
      )}
      {!showLeaderBoard && (
        <div className="farming-container" style={{ width: "300px" }}>
          <Toaster />
          <div className="farming-content" style={{ padding: "2px" }}>
            <div
              className="farming-checkout-task-container"
              onClick={OnTaskClick}
            >
              <span className="farming-checkout-task">
                Checkout the latest tasks
              </span>
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                className="farming-checkout-icon"
              />
            </div>
            <p style={{ marginTop: "30px" }}>
              <span className="farming-user-name">Hi, {userName}</span>
            </p>

            <span
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
              className="farming-user-icon"
            >
              {userName[0].toUpperCase()}
            </span>

            <span className="farming-balance-display">
              {displayBalance.toLocaleString("en-US")}
            </span>
            <p className="reward-text">
              <span
                onClick={OnRankClick}
                style={{
                  fontFamily: "Casino",
                  fontSize: "large",
                  color: "white",
                }}
              >
                CHECK YOUR RANK
                <FontAwesomeIcon
                  icon={faAngleRight}
                  style={{
                    fontSize: "large",
                    color: "white",
                    marginLeft: "10px",
                  }}
                />
              </span>
            </p>

            <div style={{ textAlign: "center" }}>
              <Button3D
                text={buttonCaption}
                onClick={handleStartClaimClick}
                active={buttonCaption != "Start" && buttonCaption != "Claim 50"}
              />
            </div>

            <button className="farming-join-community" onClick={onClickJoin}>
              <FontAwesomeIcon icon={faUsers} />
              Join The Community
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Farming;
