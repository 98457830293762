// components/BottomPopup.js
import React, { useState, useEffect } from 'react';
import './ProfileName.css';

const ProfileName = ({ onProfileClick }) => {
  const [telegramId, setTelegramId] = useState('');
  const [UserName, setUserName] = useState('');
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      setUserName(window.Telegram.WebApp.initDataUnsafe.user.first_name);
     // setTelegramId(window.Telegram.WebApp.initDataUnsafe.user.id);

    }
  }, []);

  return (
    <> 
      {(UserName == '' || UserName.toLowerCase() == 'aks' || UserName.toLowerCase() == 'ajay') ? (
        <span onClick={onProfileClick} className='profile-name'>Hi, {UserName || 'Guest'}</span>
      ) : (
        <span  className='profile-name'>Hi, {UserName || 'Guest'}</span>
      )}
    </>
  );
};

export default ProfileName;