import React from "react";
import "./Airdrop.css";

const Airdrop = () => {
  return (
    <div className="roadmap-container">
      <h2 className="roadmap-title">Roadmap</h2>
      <div className="mascot">
        <img src="images/gift.png" alt="roadmap mascot" className="mascot-img" />
      </div>
      <img
        className="roadmap-img"
        src="images/airdrop-new.png"
        alt="roadmap details"
      />
    </div>
  );
};

export default Airdrop;
