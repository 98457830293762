import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import utilities from "../../services/utilities";
import "./Leaderboard.css";

const Leaderboard = ({ OnStatusExit }) => {
  const [friends, setFriends] = useState({});
  const [top10UsersByFriends, setUsersByFriends] = useState([]);
  const [top10UsersByBalance, setUsersByBalance] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userStat, setUserStat] = useState({});

  const fetchUserStats = async () => {
    try {
      const response = await apiService.get("/user-stats");
      const userResponse = await apiService.get("/current-user");

      const userData = userResponse.data.data || {};
      setUserStat({
        name: userData.first_name,
        balance: userData.balance,
        rank: userData.rank, // Default rank until we find it
      });

      const data = response.data.data || {};
      const allUsersByBalance = data.top10UsersByBalance || [];

      allUsersByBalance.forEach((user, index) => {
        if (user._id !== userData._id) {
          console.log(
            "user",
            user._id,
            userData._id,
            user._id !== userData._id
          );
        }
      });

      // Remove the current user from the top10UsersByBalance
      const filteredTop10UsersByBalance = allUsersByBalance.filter(
        (user) => user._id !== userData.balance
      );

      setUsersByBalance(filteredTop10UsersByBalance);
      setUsersByFriends(
        Array.isArray(data.top10UsersByFriends) ? data.top10UsersByFriends : []
      );
    } catch (error) {
      console.error("Error fetching user stats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("fetching user stats");
    fetchUserStats();
  }, []);

  return (
    <div style={{ width: "98%" }} className="lb-container">
      {loading ? (
        <h2 style={{ color: "white", textAlign: "center" }}>Loading...</h2>
      ) : (
        <div className="lb-content">
          <div
            className="lb-image-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src="images/Leaderboard-256.png"
              alt="leaderboard"
              className="lb-image"
            />
          </div>
          <h3
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "26px",
              fontWeight: "bold",
            }}
          >
            Leaderboard
          </h3>

          <div className="lb-total-container">
            <span className="lb-total-text">Total</span>
            <span className="lb-total-text">67 Users</span>
          </div>
          <div style={{ width: "100%" }}>
            <li className="user-stat-container">
              <div className="lb--current-avatar">
                {userStat.name ? userStat.name[0].toUpperCase() : "U"}
              </div>
              <div className="lb-name-container">
                <span className="lb-name">{userStat.name}</span>
                <span className="lb-tokens">
                  <img src="images/coin.png" height={15} width={15} />
                  <span>{utilities.formatNumber(userStat.balance || 0)}</span>
                </span>
              </div>
              <span className="lb-rank current">#{userStat.rank}</span>
            </li>
          </div>

          <div className="lb-list-container">
            <ul className="lb-list">
              {top10UsersByBalance.map((friend, index) => (
                <li key={index} className="lb-item">
                  <div
                    className="lb-avatar"
                    style={{
                      color: index < 3 ? "#c6f726" : "white",
                      background: index < 3 ? "#2e231b" : "#4e4e4e",
                    }}
                  >
                    {friend.first_name
                      ? friend.first_name[0].toUpperCase()
                      : "U"}
                  </div>

                  <div className="lb-name-container">
                    <span className="lb-name">{friend.first_name}</span>
                    <span className="lb-tokens">
                      <img src="images/coin.png" height={15} width={15} />
                      <span>{utilities.formatNumber(friend.balance || 0)}</span>
                    </span>
                  </div>
                  <span className="lb-rank">#{friend.rank}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
