import React, { useState } from "react";
import "./EarnMoreRewards.css";

const EarnMoreRewards = ({ onConfirm, onCancel }) => {
  const [description] = useState("");

  const handleOverlayClick = (event) => {
    // Check if the click was outside the modal content
    if (event.target.className === "overlay") {
      onCancel();
    }
  };

  // return (
  //   <div
  //     className="overlay"
  //     style={{ padding: 0 }}
  //     onClick={handleOverlayClick}
  //   >
  //     <div className="earn-more-item">
  //       <h3
  //         style={{
  //           fontSize: "25px",
  //           fontFamily: "Casino",
  //           whiteSpace: "pre-line",
  //           lineHeight: "30px",
  //         }}
  //       >
  //         {"BONUS"}
  //       </h3>

  //       <div className="earnmore-icon">
  //         <img
  //           src={`images/earnmore-reward.png`}
  //           alt="earnmore-reward"
  //           width={"90%"}
  //         />
  //         <span className="red-text">47.36.23</span>
  //         <div
  //           style={{
  //             whiteSpace: "pre-line",
  //             fontWeight: "bold",
  //             lineHeight: "20px",
  //           }}
  //         >
  //           {"Invite your friends within"}
  //           <span className="green-text">48H</span>
  //           {"\n hours of joining and earn"}
  //           <span className="green-text">2X</span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="overlay">
      <div className="user-email-container">
        <div className="close-button" onClick={onCancel}>
          {"x"}
        </div>
        <h3 className="user-email-title">BONUS</h3>
        <div className="user-email-card-icon earnmore-icon">
          <img
            src={`images/earnmore-reward.png`}
            alt="earnmore-reward"
            width={"80%"}
          />
        </div>
        <p className="earnmore-description">Share your invitation link and enjoy % of your friend's points</p>
      </div>
    </div>
  );
};

export default EarnMoreRewards;
