
import React from 'react';
import './InviteLevelConfirmationDialog.css';
import BeatLoader from "react-spinners/BeatLoader";
import { useState } from "react";


const InviteLevelConfirmationDialog = ({ title, imageName, description, points, onConfirm, onCancel }) => {

  let [loading, setLoading] = useState(false);
  const handleCliamClick = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    setLoading(!loading);
    setTimeout(() => {
      onConfirm()
    }, 2000);
  }

  return (
    <div className="overlay">
      <div className="food-item">
        <div className="close-button" onClick={onCancel}>×</div>
        <h3 style={{ fontSize: '22px' }}>{title}</h3>
        <div className="card-icon">
          <img src={`images/inviteLevel/${imageName}.png`} alt={imageName} width={100} height={100} />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: 'small' }}>Congratulations! </div>
        <p>
          {description}
        </p>
        {points ? (
          <div className="point">
            <img src={`images/coin.png`} alt={title} width={24} height={24} />
            <span style={{ verticalAlign: 'top', marginLeft: '4px' }}>{points.toLocaleString()}</span>
          </div>) : 'Free'
        }

        <BeatLoader style={{ textAlign: 'center' }}
          color="#CDEB45"
          loading={loading}
          size={20}
          aria-label="Loading Spinner"
        />
        {!loading ? 
        <button className="confirm-button" onClick={handleCliamClick}>Claim</button>
        :
        <div>Processing...</div>
        }
        
      </div>

    </div>
  );
};

export default InviteLevelConfirmationDialog;
