import { ReactComponent as BakeryIcon } from "./bakery.svg";
import { ReactComponent as CasinoIcon } from "./casino.svg";
import { ReactComponent as LeaderIcon } from "./leaderboard.svg";
import { ReactComponent as FriendsIcon } from "./friends.svg";
import { ReactComponent as GiftsIcon } from "./gifts.svg";
import { ReactComponent as ShopIcon } from "./shop.svg";
import { ReactComponent as MenuIcon } from "./menu.svg";

export const tabIcons = {
  bakery: BakeryIcon,
  leader: LeaderIcon,
  friends: FriendsIcon,
  gifts: GiftsIcon,
  shop: ShopIcon,
  casino: CasinoIcon,
  menu: MenuIcon,
};
