import React, { useState, useEffect } from "react";

const CountdownButton = ({ timerCount, onComplete }) => {
  const [timeLeft, setTimeLeft] = useState(timerCount);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup interval on component unmount
    } else {
      onComplete();
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return <button className="start-button">{formatTime(timeLeft)}</button>;
};

export default CountdownButton;
