// apiService.js
import axios from "axios";

const apiService = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001/api"
      : process.env.REACT_APP_API_BASE_URL,
  // baseURL: process.env.REACT_APP_API_BASE_URL || 'https://fufuskitchen.app/api',
  // baseURL: process.env.REACT_APP_API_BASE_URL || 'https://dev.fufuskitchen.app/api',
  headers: {
    "Content-Type": "application/json",
  },
});

apiService.interceptors.request.use(
  (config) => {
    // Add any request interceptors here (e.g., adding auth tokens)
    const token = localStorage.getItem("token"); // Example token storage
    if (token) {
      //config.headers.Authorization = `Bearer ${token}`;
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle any global response errors here
    return Promise.reject(error);
  }
);

export default apiService;
