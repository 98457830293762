import React, { useState } from "react";
import "./userEmailInputDialog.css";
import { Button } from "rsuite";

const UserEmailInputDialog = ({ onConfirm, onInvalidEmail, onCancel }) => {
  const [userEmail, setuserEmail] = useState("");
  const [descrption] = useState(
    "Early access is only reserved for active players. Invite friends, climb up ladder and gain access to once in lifetime opportunity."
  );

  const handleConfirmClick = () => {
    if (userEmail === "") {
      onInvalidEmail();
    } else {
      onConfirm(userEmail);
    }
  };

  return (
    <div className="overlay">
      <div className="user-email-container">
        <div className="close-button" onClick={onCancel}>
          {"x"}
        </div>
        <h3 className="user-email-title">GET IN EARLY</h3>
        <div className="user-email-card-icon">
          <img
            src="images/inviteLevel/Level_3.png"
            alt="inviteLevel"
            width={150}
            height={150}
          />
        </div>
        <p className="user-email-description">{descrption}</p>
        <input
          type="email"
          value={userEmail}
          onChange={(e) => setuserEmail(e.target.value)}
          className="user-email-input"
        />
        <Button
          appearance="link"
          className="user-email-submit-button"
          onClick={handleConfirmClick}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default UserEmailInputDialog;
